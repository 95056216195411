import React from "react";

import {
  EvolutionContainer,
  EvolutionChainContainer,
  EvolutionBox,
  EvolutionImage,
  EvolutionTitle,
  EvolutionName,
  MultiGroup,
  MultiStage,
} from "./Evolution.styles";

export default function Evolution({ chain_of_evolution, info }) {
  const getEvolution = (chain_of_evolution) => {
    const data = chain_of_evolution;

    const getName = (name) =>
      name
        .split("-")
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(" ");

    const getId = (uri) => {
      let uriArray = uri.split("/");
      const number = Number(uriArray[uriArray.length - 2]);
      return number;
    };

    const populateEvolutions = (chain) => {
      if (!chain.evolves_to) return;
      else
        return {
          name: getName(chain.species.name),
          id: getId(chain.species.url),
          isBaby: chain.is_baby,
          hasEvolved: true,

          evolvesTo: [
            ...chain.evolves_to.map((evolution) =>
              populateEvolutions(evolution)
            ),
          ],
        };
    };

    const chain = {
      name: getName(data.species.name),
      id: getId(data.species.url),
      isBaby: data.is_baby,
      hasEvolved: false,

      evolvesTo: [...data.evolves_to.map((evo) => populateEvolutions(evo))],
    };

    return chain;
  };

  let evolution = {};

  if (Object.keys(chain_of_evolution).length !== 0) {
    evolution = getEvolution(chain_of_evolution);
  }

  const EvolutionChain = ({ chain, color }) => {
    return (
      <>
        <EvolutionBox to={`/pokemons/${chain.id}`}>
          <EvolutionImage
            src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${chain.id}.png`}
            alt={chain.name}
          />
          <EvolutionName>{chain.name}</EvolutionName>
        </EvolutionBox>
        {chain.evolvesTo.length <= 2 && (
          <div style={{ marginLeft: "2rem" }}>
            {chain.evolvesTo.map((next) => (
              <EvolutionChain key={next.id} chain={next} color={color} />
            ))}
          </div>
        )}
        {chain.evolvesTo.length > 2 && (
          <MultiGroup>
            <MultiEvolution color={color} chain={chain} />
          </MultiGroup>
        )}
      </>
    );
  };

  const MultiEvolution = ({ chain, color }) => {
    return (
      <>
        {/* <BsChevronRight color={color} /> */}

        <MultiStage>
          {chain.evolvesTo.map((next) => (
            <EvolutionBox to={`/pokemons/${next.id}`}>
              <EvolutionImage
                src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${next.id}.png`}
                alt={next.name}
              />
              <EvolutionName>{next.name}</EvolutionName>
            </EvolutionBox>
          ))}
        </MultiStage>
      </>
    );
  };

  return (
    <EvolutionContainer>
      <EvolutionTitle>Evolution</EvolutionTitle>
      {Object.keys(evolution).length > 0 ? (
        <>
          {evolution.evolvesTo.length === 0 ? (
            <EvolutionChainContainer>
              <EvolutionBox to={`/pokemons/${evolution.id}`}>
                <EvolutionImage
                  src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${evolution.id}.png`}
                  alt={evolution.name}
                />
                <EvolutionName>{evolution.name}</EvolutionName>
              </EvolutionBox>
            </EvolutionChainContainer>
          ) : (
            <EvolutionChainContainer>
              <EvolutionChain chain={evolution} color="#000" />
            </EvolutionChainContainer>
          )}
        </>
      ) : (
        <EvolutionChainContainer>
          <EvolutionBox to={`/pokemons/${info.id}`}>
            <EvolutionImage
              src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${info.id}.png`}
              alt={info.name}
            />
            <EvolutionName>{info.name}</EvolutionName>
          </EvolutionBox>
        </EvolutionChainContainer>
      )}
    </EvolutionContainer>
  );
}
