import React from "react";

import { useDispatch } from "react-redux";
import { setPagination } from "../../store/actions";

import Button from "react-bootstrap/Button";

import { 
  PaginationContainer,
  PaginationText
} from "./Pagination.styles";

export default function Pagination(props) {
  const max = Math.ceil(props.total / props.limit);
  const dispatch = useDispatch();

  return (
    <>
      <PaginationText>
        Showing{" "}
        <span style={{ fontWeight: "bold" }}>
          &nbsp;{props.page === 1 ? 1 : (props.page - 1) * props.limit + 1} to{" "}
          {props.page === max ? props.total : props.page * props.limit}{" "}
        </span>
        &nbsp;of <strong>&nbsp;{props.total}&nbsp;</strong>results
      </PaginationText>
      <PaginationContainer>
        {props.page > 1 && (
          <Button
            style={{
              marginRight: "0.5em",
              marginLeft: "0.5em",
            }}
            variant="dark"
            onClick={() => dispatch(setPagination(props.page - 1))}
          >
            Previous
          </Button>
        )}
        {props.page < max && (
          <Button
            style={{
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            variant="dark"
            onClick={() => dispatch(setPagination(props.page + 1))}
          >
            Next
          </Button>
        )}
      </PaginationContainer>
    </>
  );
}