import React from "react";

import { TypeContainer, Type } from "./Types.styles";

export default function TypesComponent({ pokemon }) {
  const typeColor = {
    bug: "#729f3f",
    dark: "#707070",
    dragon: "#53a4cf",
    electric: "#eed535",
    fairy: "#fdb9e9",
    fighting: "#d56723",
    fire: "#fd7d24",
    flying: "#3dc7ef",
    ghost: "#7b62a3",
    grass: "#9bcc50",
    ground: "#f7de3f",
    ice: "#51c4e7",
    normal: "#a4acaf",
    poison: "#b97fc9",
    psychic: "#f366b9",
    rock: "#a38c21",
    steel: "#9eb7b8",
    water: "#4592c4",
  };

  return (
    <>
      <TypeContainer>
        {pokemon.types.map((type, index) => (
          <Type bg={typeColor[type.type.name]} key={index}>
            {type.type.name}
          </Type>
        ))}
      </TypeContainer>
    </>
  );
}
