import styled from "styled-components";

export const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const Type = styled.span`
  background-color: ${(props) => props.bg};
  width: 90%;
  height: 90%;
  color: white;
  margin: 0.5em;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  padding: 0.2em 0.5em;
  border-radius: 0.5em;

  @media (max-width: 768px) {
    width: 30%;
    height: 30%;
  }
`;
