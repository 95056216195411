import React from "react";
import NavbarItem from "../../components/Navbar";

import { Container, Features, Footer } from "./Pokedex.styles";

import gitlab from "../../img/gitlab-logo.png"

export default function Pokedex() {
  return (
    <>
      <NavbarItem />
      <Container>
        <h1
          style={{
            textAlign: "center",
            fontSize: "2.5rem",
            fontWeight: "600",
            marginBottom: "20px",
          }}
        >
          Pokedex
        </h1>
        <h1
          style={{
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "600",
          }}
        >
          Information about the Project
        </h1>
        <p>
          The project consists in the creation of a frontend React application
          to consume and visualize infomation coming from an API service.
          <br />
          It uses React for the interface, Styled Components for CSS styling,
          React Router for navigation and Redux for state management.
        </p>
        <h1
          style={{
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "600",
            marginTop: "10px",
          }}
        >
          API
        </h1>
        <p>
          The API used in this project is the
          <a
            href="https://pokeapi.co/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#ffe031",
              textDecoration: "none",
              fontWeight: "600",
              marginLeft: "5px",
            }}
          >
            PokéAPI
          </a>
          <br />
          It is a RESTful API that provides a wealth of information about the
          Pokémon universe.
        </p>
        <Features>
          <h1
            style={{
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "600",
              marginBottom: "20px",
            }}
          >
            Features
          </h1>
          <ul>
            <li>
              <strong>Pokemon List</strong>: It shows a list of all the Pokémon
              available in the API.
            </li>
            <li>
              <strong>Pokemon Details</strong>: It shows the details of a
              Pokémon, including its name, image, types, abilities and stats.
            </li>
            <li>
              <strong>Search</strong>: It allows the user to search for a
              Pokémon by name.
            </li>
            <li>
              <strong>Pagination</strong>: It allows the user to navigate
              through the Pokémon list.
            </li>
            <li>
              <strong>Filter</strong>: It allows the user to filter the Pokémon
              by type.
            </li>
          </ul>
        </Features>
      </Container>

      <Footer>
        {/* link to gitlab repository */}
        <a
          href="https://gitlab.com/pw-mctw/tdw-2022/tdw-mp2-diogo-cruz"
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#000",
            textDecoration: "none",
            fontWeight: "600",
            marginLeft: "5px",
          }}
        >
          <img
            src={gitlab}
            alt="Gitlab"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "60%",
              height: "60%",
            }}
          />
        </a>
      </Footer>
    </>
  );
}
