import styled from "styled-components";

export const AbilitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 20px;
`;

export const AbilitiesTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

export const Abilities = styled.div`
  display: block;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

export const Ability = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  padding: 5px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const AbilityName = styled.span`
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  margin-top: 5px;
`;
