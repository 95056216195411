import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
`;

export const PaginationText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  padding-bottom: 1em;
`;
