import React, { useEffect } from "react";

import { getAllPokemons } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

import FilterType from "../../components/Filter/FilterType/FilterType";
import FilterName from "../../components/Filter/FilterOrderName/FilterName";
import Pagination from "../../components/Pagination/Pagination";
import PokemonCard from "../../components/PokemonCard/PokemonCard";
import Navbar from "../../components/Navbar";
import SearchBar from "../../components/SearchBar/SearchBar";

import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";

import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 ato;
`;

export default function PokemonList() {
  const dispatch = useDispatch();
  const pokemons = useSelector((state) => state.filteredPokemons);
  const page = useSelector((state) => state.page);

  const POKEMON_PER_PAGE = 20;

  useEffect(() => {
    dispatch(getAllPokemons());
  }, [dispatch]);

  const getPokemonId = (pokemon) => {
    if (pokemon.url !== undefined) {
      return pokemon.url.split("/")[6];
    } else {
      return pokemon.id;
    }
  };

  return (
    <>
      <Navbar />
      <FilterContainer>
        <SearchBar />
        <FilterType />
        <FilterName />
      </FilterContainer>
      <Container>
        <Row xs={2} md={3} lg={4} className="g-4 ">
          {pokemons
            .slice((page - 1) * POKEMON_PER_PAGE, page * POKEMON_PER_PAGE)
            .map((pokemon) => (
              <Col key={getPokemonId(pokemon)}>
                <PokemonCard
                  key={pokemon.name}
                  pokeId={getPokemonId(pokemon)}
                />
              </Col>
            ))}
        </Row>
        <Pagination
          page={page}
          total={pokemons.length}
          limit={POKEMON_PER_PAGE}
        />
      </Container>
    </>
  );
}
