import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  width: 80%;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const PokemonDetailsContainer = styled.div`
  width: 50%;
  max-width: 800px;
  max-height: 800px;
  margin: 0 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const PokemonDetailsBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PokemonDetailsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 15px;
  text-transform: capitalize;
`;

export const PokemonDetailsName = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 20px;
`;

export const PokemonDetailsId = styled.h6`
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
`;

export const PokemonDetailsImage = styled.img`
  align-self: flex-start;
  margin-left: 30px;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;
