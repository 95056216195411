import React, { useState } from "react";

import { useSelector } from "react-redux";

import PokemonDetailsPage from "../../views/PokemonDetails/PokemonDetailsPage";

import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardButton,
  CardImg,
  TypePill,
} from "./PokemonCard.styles";

export default function PokemonCard({ pokeId }) {
  const [view, setView] = useState(true);
  const pokemon = useSelector((state) => state.pokemonDefault[pokeId]);

  if (pokemon === undefined) {
    return <Card></Card>;
  }

  // background color for each type
  const backgroundColor = {
    bug: "#729f3f",
    dark: "#707070",
    dragon: "#53a4cf",
    electric: "#eed535",
    fairy: "#fdb9e9",
    fighting: "#d56723",
    fire: "#fd7d24",
    flying: "#3dc7ef",
    ghost: "#7b62a3",
    grass: "#9bcc50",
    ground: "#f7de3f",
    ice: "#51c4e7",
    normal: "#a4acaf",
    poison: "#b97fc9",
    psychic: "#f366b9",
    rock: "#a38c21",
    steel: "#9eb7b8",
    water: "#4592c4",
  };

  return (
    <>
      <Card>
        <CardImg img={pokemon.sprites.front_default} />
        <CardBody>
          <CardTitle>
          {/* check size of name and shorten if too big */}
            {pokemon.name.length > 10
              ? pokemon.name.slice(0, 10) + "..."
              : pokemon.name
            }
          </CardTitle>
          <CardText>
            {pokemon.types.map((type) => (
              <TypePill
                bg={backgroundColor[type.type.name]}
                key={type.type.name}
              >
                {type.type.name}
              </TypePill>
            ))}
          </CardText>
          <CardButton
            as={Link}
            to={`/pokemons/${pokemon.id}`}
            onClick={() => {
              setView(!view);
            }}
            style={{ textDecoration: "none" }}
          >
            {view ? "More Details" : <PokemonDetailsPage />}
          </CardButton>
        </CardBody>
      </Card>
    </>
  );
}
