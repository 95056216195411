import React from "react";

import Navbar from "../../components/Navbar";

import { useDispatch, useSelector } from "react-redux";
import { getPokemonDetailsByUrl } from "../../store/actions";

import { useParams } from "react-router-dom";

import {
  Container,
  PokemonDetailsContainer,
  PokemonDetailsImage,
  PokemonDetailsName,
  PokemonDetailsBody,
  PokemonDetailsText,
  PokemonDetailsId
} from "./PokemonDetails.styles";

import Stats from "../../components/DetailsInfo/Stats/StatsComponent";
import Abilities from "../../components/DetailsInfo/Abilities/AbilitiesComponent";
import Types from "../../components/DetailsInfo/Type/TypesComponent";
import Evolution from "../../components/DetailsInfo/Evolution/Evolution";

export default function PokemonDetailsPage() {
  const { id } = useParams();
  const pokemon = useSelector((state) => state.pokemonDetails[id]);

  const dispatch = useDispatch();
  if (pokemon === undefined) {
    dispatch(getPokemonDetailsByUrl("https://pokeapi.co/api/v2/pokemon/" + id));
  }
  return (
    <>
      <Navbar />
      <Container>
        {pokemon !== undefined ? (
          <PokemonDetailsContainer>
            <>
              <PokemonDetailsName>{pokemon.name}</PokemonDetailsName>
              <PokemonDetailsId>(#{pokemon.id})</PokemonDetailsId>
              <PokemonDetailsBody>
                <PokemonDetailsImage
                  src={pokemon.sprites.front_default}
                  alt="pokemon"
                />
                <Types pokemon={pokemon} />
                <Abilities pokemon={pokemon} />
              </PokemonDetailsBody>
              <PokemonDetailsText>
                Height:&nbsp;
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {pokemon.height/10}m
                </span>
                &nbsp;&nbsp;
                Weight:&nbsp;
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                {pokemon.weight/10}kg
                </span>
              </PokemonDetailsText>
              <PokemonDetailsText>
                Base experience:&nbsp;
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {pokemon.base_experience}xp
                </span>
              </PokemonDetailsText>
              <Stats pokemon={pokemon} />
            </>
          </PokemonDetailsContainer>
            ) : (
            <h4>Loading...</h4>
          )}
        {pokemon !== undefined && pokemon.chain_of_evolution !== undefined && (
          <Evolution
            chain_of_evolution={pokemon.chain_of_evolution}
            info={pokemon}
          />
        )}
      </Container>
    </>
  );
}
