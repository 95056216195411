import React from "react";

import {
  StatsContainer,
  Stats,
  Stat,
  StatValue,
  StatName,
} from "./Stats.styles";

export default function StatsComponent({ pokemon }) {
  return (
    <>
      <StatsContainer>
        <Stats>
          {pokemon.stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.base_stat}</StatValue>
              <StatName>{stat.stat.name}</StatName>
            </Stat>
          ))}
        </Stats>
      </StatsContainer>
    </>
  );
}
