import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import pokedex from "../img/pokedex.png";

export default function NavbarItem() {
  return (
    <Navbar key="sm" bg="dark" variant="dark" expand="sm" className="mb-3">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={pokedex} alt="logo" width="22%" height="22%" />
        </Navbar.Brand>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-$"sm"`}
          aria-labelledby={`offcanvasNavbarLabel-expand-$"sm"`}
          placement="end"
        >
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/pokemons">
                <div
                  style={{
                    color: "white",
                    fontSize: "1.1rem",
                  }}
                >
                  Pokemon List
                </div>
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
