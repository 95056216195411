import React from "react";
import { useDispatch } from "react-redux";
import { getPokemonsByType, setPagination } from "../../../store/actions";

import {
  TypeContainer,
  SelectFilter,
  FilterOption,
} from "../Filter.styles";

export default function FilterType() {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(setPagination(1));
    dispatch(getPokemonsByType(e.target.value));
  };

  const allTypes = [
    "all Types",
    "normal",
    "fighting",
    "flying",
    "poison",
    "ground",
    "rock",
    "bug",
    "ghost",
    "steel",
    "fire",
    "water",
    "grass",
    "electric",
    "psychic",
    "ice",
    "dragon",
    "dark",
    "fairy",
    "shadow",
  ];

  return (
    <TypeContainer>
      <SelectFilter onChange={handleChange}>
        {allTypes.map((type) => (
          <FilterOption key={type} value={type} default={type === "all Types"}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </FilterOption>
        ))}
      </SelectFilter>
    </TypeContainer>
  );
}
