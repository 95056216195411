import styled from "styled-components";
import { Link } from "react-router-dom";

export const EvolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const EvolutionChainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border-radius: 10px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const EvolutionBox = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  margin-bottom: 20px;
`;

export const EvolutionImage = styled.img`
  width: 160px;
  height: 160px;

  @media (max-width: 768px) {
    width: 110px;
    height: 110px;
  }
`;

export const EvolutionTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 20px;
`;

export const EvolutionName = styled.p`
  font-size: 15px;
  font-weight: 600;
  margin: 0;
`;

export const MultiGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MultiStage = styled.div`
  display: flex;
  alignitems: center;
  width: 80%;
  flexwrap: wrap;
  div {
    flexbasis: 20%;
  }
`;
