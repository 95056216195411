import React from "react";
import { useDispatch } from "react-redux";
import { searchPokemon, setPagination } from "../../store/actions";

import { SearchBarContainer, SearchBarInput } from "./SearchBar.styles";

export default function SearchBar() {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(setPagination(1));
    dispatch(searchPokemon(e.target.value));
  };

  return (
    <SearchBarContainer>
      <SearchBarInput
        type="search"
        placeholder="Search +1000 Pokemons"
        className="me-2"
        aria-label="Search"
        onChange={handleChange}
      />
    </SearchBarContainer>
  );
}
