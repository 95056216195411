import {
  GET_POKEMON_DETAILS_BY_URL,
  GET_POKEMONS_BY_TYPE,
  GET_POKEMONS_BY_NAME,
  GET_POKEMON_DEFAULT,
  GET_ALL_POKEMONS,
  SEARCH_POKEMON,
  SET_PAGE,
} from "./types";
import axios from "axios";

const API_URL = "https://pokeapi.co/api/v2/pokemon?limit=1154";

export const getAllPokemons = () => async (dispatch) => {
  try {
    const localStorageList = localStorage.getItem("pokemons");
    let pokemons;
    if (localStorageList !== null) {
      pokemons = JSON.parse(localStorageList);
      dispatch({
        type: GET_ALL_POKEMONS,
        payload: pokemons,
      });
    } else {
      const res = await axios.get(API_URL);

      localStorage.setItem("pokemons", JSON.stringify(res.data.results));

      dispatch({
        type: GET_ALL_POKEMONS,
        payload: res.data.results,
      });
      pokemons = res.data.results;
    }
    pokemons.forEach((pokemon) => {
      dispatch(getPokemonDefault(pokemon.url));
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPokemonDefault = (url) => async (dispatch) => {
  try {
    const res = await axios.get(url);
    const data = {
      [res.data.id]: {
        name: res.data.name,
        id: res.data.id,
        types: res.data.types,
        sprites: {
          front_default:
            res.data.sprites.other["official-artwork"].front_default,
        },
      },
    };
    dispatch({
      type: GET_POKEMON_DEFAULT,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPokemonDetailsByUrl = (url) => async (dispatch) => {
  try {
    const res = await axios.get(url);

    let chain_of_evolution = {};
    let key = res.data.id;

    // all that are NOT subspecies
    if (key < 10000){

      const resEvo = await axios.get(
        "https://pokeapi.co/api/v2/pokemon-species/" + key
      );

      if (resEvo.data.evolution_chain !== null) {

        const resChain = await axios.get(resEvo.data.evolution_chain.url);
        chain_of_evolution = resChain.data.chain;
      }
    }

    const data = {
      [key]: {
        name: res.data.name,
        id: res.data.id,
        types: res.data.types,
        sprites: {
          front_default:
            res.data.sprites.other["official-artwork"].front_default,
        },
        stats: res.data.stats,
        chain_of_evolution: chain_of_evolution,
        height: res.data.height,
        weight: res.data.weight,
        abilities: res.data.abilities,
        base_experience: res.data.base_experience,
      },
    };

    dispatch({
      type: GET_POKEMON_DETAILS_BY_URL,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const searchPokemon = (term) => async (dispatch) => {
  try {
    const res = localStorage.getItem("pokemons");
    const pokemons = JSON.parse(res);
    const filteredPokemons = pokemons.filter((pokemon) =>
      pokemon.name.includes(term.toLowerCase())
    );

    if (term === "") {
      dispatch({
        type: SEARCH_POKEMON,
        payload: pokemons,
      });
    } else {
      dispatch({
        type: SEARCH_POKEMON,
        payload: filteredPokemons,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPokemonsByType = (type) => async (dispatch) => {
  try {
    const res = localStorage.getItem("pokemons");
    const pokemons = JSON.parse(res);
    const filteredPokemons = pokemons.filter((pokemon) =>
      pokemon.types.some((t) => t.type.name === type)
    );

    if (type === "all Types") {
      dispatch({
        type: GET_POKEMONS_BY_TYPE,
        payload: pokemons,
      });
    } else {
      dispatch({
        type: GET_POKEMONS_BY_TYPE,
        payload: filteredPokemons,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPokemonsByName = (order) => async (dispatch) => {
  try {
    const res = localStorage.getItem("pokemons");
    const pokemons = JSON.parse(res);
    const filteredPokemons = pokemons.sort((a, b) => {
      if (order === "asc") {
        return a.name.localeCompare(b.name);
      } else if (order === "desc") {
        return b.name.localeCompare(a.name);
      } else {
        return a.id - b.id;
      }
    });

    dispatch({
      type: GET_POKEMONS_BY_NAME,
      payload: filteredPokemons,
    });
  } catch (error) {
    console.log(error);
  }
}

export const setPagination = (page) => (dispatch) => {
  dispatch({
    type: SET_PAGE,
    payload: page,
  });
  // code to scroll to top of page
  window.scrollTo(0, 0);
};