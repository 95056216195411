import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 180px;
  max-height: 180px;
  border: 1px solid #d6d6d6;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  &:hover {
    transform: scale(1.1);
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CardImg = styled.img.attrs((props) => ({
  src: props.img,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 115px;
  height: 100%;
  max-height: 115px;
  margin: 0.5rem;
`;

export const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  text-transform: capitalize;
  margin-top: 0.5rem;
`;

export const CardText = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.8rem;
`;

export const CardButton = styled.button`
  display: flex;
  margin-bottom: 0.5rem;
  display: block;
  border: 1px solid #007bff;
  border-radius: 0.25rem;
  padding: 0.25rem 0.25rem;
  font-size: 0.875rem;
  color: #007bff;
  border-color: #007bff;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
`;

export const TypePill = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bg};
  color: white;
  margin: 0.3em;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  padding: 0.2em 1em;
  border-radius: 0.5em;
`;
