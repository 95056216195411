import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 80%;
  text-align: center;
  padding-top: 10%;
`;

export const ErrorCode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    width: 150px;
    height: 150px;
  }
`;

export const ButtonLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 1rem;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: #f5d8d8;
  }
`;
