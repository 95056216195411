import React from "react";
import { useDispatch } from "react-redux";
import { getPokemonsByName, setPagination } from "../../../store/actions";

import {
  TypeContainer,
  SelectFilter,
  FilterOption,
} from "../Filter.styles";

export default function FilterName() {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const order = e.target.value[e.target.value.length - 1];
    if (order === "+") {
      dispatch(getPokemonsByName("asc"));
    } else if (order === "-") {
      dispatch(getPokemonsByName("desc"));
    } else {
      dispatch(getPokemonsByName("default"));
    }
    dispatch(setPagination(1));
  };

  return (
    <TypeContainer
      style={{
        padding: "0 0 0 10px",
      }}
    > 
      <SelectFilter onChange={handleChange}>
        <option value="default">Name</option>
        <option value="name+">A-Z</option>
        <option value="name-">Z-A</option>
      </SelectFilter>
    </TypeContainer>
  );
}