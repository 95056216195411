import { useRouteError } from "react-router-dom";
import {
  Container,
  ErrorContainer,
  ErrorCode,
  ButtonLink,
} from "./ErrorPage.styles";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container>
      <ErrorContainer>
        <ErrorCode>
          <h1
            style={{
              fontSize: "10rem",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            404
          </h1>
          <img src="./error.png" alt="Voltorb" />
        </ErrorCode>
        <h2
          style={{
            fontSize: "1.5rem",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          Page not found
        </h2>
        <ButtonLink to="/">Go back to home</ButtonLink>
      </ErrorContainer>
    </Container>
  );
}
