import styled from "styled-components";

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const Stats = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100px;
  height: 100%;
  margin: 0 5px;
  padding: 5px;
  text-transform: uppercase;
`;

export const StatValue = styled.div`
  font-size: 25px;
  font-weight: 700;
  text-align: center;
`;

export const StatName = styled.span`
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  margin-top: 5px;
`;
