import React from "react";

import {
  AbilitiesContainer,
  Abilities,
  Ability,
  AbilityName,
  AbilitiesTitle,
} from "./Abilities.styles";

export default function AbilitiesComponent({ pokemon }) {
  return (
    <>
      <AbilitiesContainer>
        <AbilitiesTitle>Abilities</AbilitiesTitle>
        <Abilities>
          {pokemon.abilities.map((ability, index) => (
            <Ability key={index}>
              <AbilityName>{ability.ability.name}</AbilityName>
            </Ability>
          ))}
        </Abilities>
      </AbilitiesContainer>
    </>
  );
}
