import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import "./index.css";

import ErrorPage from "./views/Error/ErrorPage";
import Pokedex from "./views/Pokedex/Pokedex";
import PokemonList from "./views/PokemonList/PokemonList";
import PokemonDetailsPage from "./views/PokemonDetails/PokemonDetailsPage";

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />
  },
  {
    path: "/",
    element: <Pokedex />
  },
  {
    path: "/pokemons",
    element: <PokemonList />
  },
  {
    path: "/pokemons/:id",
    element: <PokemonDetailsPage />
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);
